
import ClickOutside from 'vue-click-outside'
import ChevronIcon from '@/static/svg/chevron-icon.svg'

export default {
  components: {
    ChevronIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '--',
    },
    autoReOrder: {
      type: Boolean,
      default: false,
    },
    padLabelLength: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  computed: {
    label() {
      return this.options.find((option) => option.value === this.inputValue)?.label || ''
    },
    longestLabelCharLength() {
      return Math.max(...this.options.map((option) => option.label?.length || 0))
    },
    labelPadding() {
      const labelLengthDifference = this.longestLabelCharLength - this.label.length
      if (labelLengthDifference <= 0) {
        return ''
      }
      return new Array(labelLengthDifference + 1).join(' ')
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.popupItem = this.$el
  },
  methods: {
    handleDropdown() {
      if (this.isOpened) {
        this.close()
        return
      }
      this.open()
    },
    open() {
      this.isOpened = true
      this.$nextTick(() => {
        if (this.$refs.optionsDrawer) {
          const padding = 10
          const globalHeaderHeight = document.querySelector('header')?.offsetHeight || 0
          const { height: optionsHeight, y: optionsY } = this.$refs.optionsDrawer.getBoundingClientRect()
          const scrollUpBy = optionsHeight - (window.innerHeight - optionsY)
          const scrollDownBy = optionsY - globalHeaderHeight

          if (scrollUpBy > 0) {
            window.scrollBy(0, scrollUpBy + padding)
          } else if (scrollDownBy < 0) {
            window.scrollBy(0, scrollDownBy - padding)
          }
        }
      })
    },
    close() {
      this.isOpened = false
    },
    onSelect(option) {
      this.inputValue = option.value
      this.isOpened = false

      if (this.autoReOrder) {
        this.$emit('changeFrequency')
      }
    },
  },
}
